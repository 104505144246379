import React from "react";
import Footer from "../Shared/Footer";
// import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Header from "../Shared/Header";

const Checkout = () => {
    const navigate = useNavigate();

    const checckout = () =>{
        navigate('/shippingaddress');
    }

    return (
        <div class="frame-ASD" style={{ background: "#2d0909" }}>
            <div className="container pb-5" >
                <Header />
                <div className="checkout d-flex justify-content-center mt-5">
                    <div className="checkout_card col-md-4 text-white p-4">
                        <h4 className="">Checkout</h4>
                        <div className="d-md-flex justify-content-between align-items-center mt-4">
                            <div className="product_detail">
                                <span>
                                    <img src="logo192.png" style={{ height: "60px", width: "60px" }} />
                                </span>
                                <span className="product_name" style={{ paddingLeft: "10px" }}>Life Code</span>
                            </div>
                            <span className="product_price">600Rs.</span>
                        </div>
                        <div className="paymeny_section">
                            <div className="coupon_input my-4 d-flex">
                                <input type="text" className="col-9" />
                                <button className="col-3">Apply</button>
                            </div>
                            <div>
                                <div className="payment_detail pb-4">
                                    <div className="d-flex justify-content-between">
                                        <span className="left_heading">Subtotal</span>
                                        <span className="right_text">600.00Rs</span>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="left_heading">Delivery & Handling</span>
                                        <span className="right_text">20.00Rs</span>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="left_heading">Discount</span>
                                        <span className="right_text">100.00Rs</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <span className="total_amount">Total</span>
                                    <span className="total_amount">520.00Rs</span>
                                </div>
                                <div className="mt-4 place_order_btn">
                                    <button onClick={checckout}>Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Checkout;