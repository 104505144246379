import React from "react";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

const ShippingAddress = () => {
    return (
        <div class="frame-ASD" style={{ background: "#2d0909" }}>
            <div className="container pb-5" >
                <Header />
                <section class=" billing_address_page">
                    <div class="bg_pic">
                        <div class="hedding_product my-5">
                            <h2>Billing Address</h2>
                        </div>
                        <div class="from_wepper">
                            <p>Billing Address</p>
                            <span>Select a billing address from your address book or enter a new address.</span>
                            <form action="" method="get">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <input type="text" name="fullname" id="fullname" class="form-control"
                                                placeholder="Full Name" required/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <input type="text" name="email" id="email" class="form-control" placeholder="Email"
                                                required/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <input type="text" name="mobilenumber" id="mobilenumber" class="form-control"
                                                placeholder="Mobile Number" required/>
                                        </div>
                                    </div> 
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <input type="text" name="address" id="address" class="form-control"
                                                placeholder="Address" required/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <select data-trigger="country-select" required>
                                                <option value="0">Select country</option>
                                                <option value="1">United States</option>
                                                <option value="3">Afghanistan</option>
                                                <option value="4">Albania</option>
                                                <option value="5">Algeria</option>
                                                <option value="6">American Samoa</option>
                                                <option value="7">Andorra</option>
                                                <option value="8">Angola</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <select>
                                                <option value="0">Select state</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <input type="text" name="city" id="city" class="form-control" placeholder="City"
                                                required/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <input type="text" name="zip" id="zip" class="form-control"
                                                placeholder="Zip / postal code" required/>
                                        </div>
                                    </div>                                  
                                    <button type="submit" id="" class="button_con">proceed to payment</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default ShippingAddress;