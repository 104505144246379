import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import Home from './components/Home';
import Register from './components/Register';
import Checkout from './components/Checkout';
import ShippingAddress from './components/ShippingAddress';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="shippingaddress" element={<ShippingAddress />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
