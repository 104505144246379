import React from "react";

const Header = () => {
    return (
        <>
            <div class="fw-row">
                <div class="fw-col-xs-12 ">
                    <div class="fw-divider-space" style={{ marginTop: "50px" }}></div>
                </div>
            </div>
            <div class="fw-row">
                <div class="navbar-frame">
                    <a href="index.html"><img class="navbar-left-content" src="./assets/section-video/lifecode-logo.svg" /></a>
                    <div class="navbar-right-content" style={{ display: "flex" }}>
                        <div className="d-flex align-items-center">
                            <img class="icon-call-sms" src="./assets/section-video/call-calling.svg" />
                            {/* <p class="navbar-right-text mb-0"> +91 9829197777</p> */}
                            <a href="tel:+919829197777" class="navbar-right-text mb-0">+91 9829197777</a>

                        </div>

                        <div class="separator-1 vector-class"></div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img class="icon-call-sms vector-class" src="./assets/section-video/sms.svg" />
                            <p class="navbar-right-text vector-class mb-0">sales@lifecode.co.in</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;